// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.store/css-loader@3.6.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"variables.scss"}]);
// Exports
exports.locals = {
	"menuText": "#000",
	"menuActiveText": "#cfbd95",
	"subMenuActiveText": "#000",
	"menuBg": "#fff",
	"menuHover": "#f2f2f2",
	"subMenuBg": "#f2f2f2",
	"subMenuHover": "#fff",
	"sideBarWidth": "210px",
	"subMenuActiveBg": "#000"
};
module.exports = exports;
